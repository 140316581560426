import request from "@/request";

export const getContactUs = (data: any) => {
  return request.post({
    url: "/v-user/users/contact/us",
    data: {
      companyNickname: data.companyNickname,
      websiteAddress: data.websiteAddress,
      headquarterBase: data.headquarterBase,
      groupScale: data.groupScale,
      mainBusiness: data.mainBusiness,
      mayaVersion: data.mayaVersion,
      ueVersion: data.ueVersion,
      renderer: data.renderer,
      renderEngine: data.renderEngine,
      isUseDec: data.isUseDec,
      isKnowDec: data.isKnowDec,
      interested: data.interested,
      contactNickname: data.contactNickname,
      position: data.position,
      mobile: data.mobile,
      email: data.email,
      remark: data.remark,
    },
  });
};
